.play-pause-button, .download-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.play-pause-button {
    background-color: #4CAF50; /* Green */
    color: white;
}

.download-button {
    background-color: #008CBA; /* Blue */
    color: white;
}

.play-pause-button:hover, .download-button:hover {
    opacity: 0.8;
}